<template>
  <validation-observer ref="simpleRules">
    <b-card-code
      title="Tulis Surat Masuk"
      :class="$route.name == 'detail-surat-keluar' ? 'detail-dispo' : '' && authorize"
    >
      <b-button
        v-show="$route.name === 'detail-surat-keluar'"
        variant="outline-primary"
        class="bg-gradient-primary mt-2"
        style="position: absolute;right: 15px;top: -10px;"
        type="submit"
        @click.prevent="goEdit"
      >
        <span class="align-middle">Edit Surat Keluar</span>
      </b-button>
      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="No.Surat Keluar"
            rules="required"
          >
            <b-form-group
              label="No.Surat Keluar"
              label-for="No.Surat Keluar"
            >
              <b-form-input
                id="No.Surat Keluar"
                v-model="NoSurat"
                disabled
                placeholder="Input No. Surat Keluar"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Kategori"
            label-for="Kategori"
          >
            <v-select
              v-model="selectedKategori"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              :disabled="$route.name !== 'edit-surat-keluar'"
              placeholder="Pilih Keputusan"
              :options="optionsKategori"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Kepada"
            rules="required"
          >
            <b-form-group
              label="Kepada"
              label-for="Kepada"
            >
              <b-form-input
                id="Kepada"
                v-model="to"
                placeholder="Input Kepada"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="6">

          <validation-provider
            #default="{ errors }"
            name="Perihal"
            rules="required"
          >
            <b-form-group
              label="Perihal"
              label-for="Perihal"
            >
              <b-form-input
                id="Perihal"
                v-model="Perihal"
                placeholder="Input Perihal"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Instansi"
            rules="required"
          >
            <b-form-group
              label="Instansi"
              label-for="Instansi"
            >
              <b-form-input
                id="Instansi"
                v-model="agency"
                placeholder="Input Instansi"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Alamat"
            rules="required"
          >
            <b-form-group
              label="Alamat"
              label-for="Alamat"
            >
              <b-form-input
                id="Alamat"
                v-model="address"
                placeholder="Input Alamat"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tanggal"
            rules="required"
          >
            <b-form-group
              label="Tanggal"
              label-for="Tanggal"
            >
              <b-form-datepicker
                id="date-datepicker"
                v-model="startDate"
                locale="id"
                class="mb-1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-form-group
        label="File Lampiran"
        style="position:relative"
      >
        <!-- <div
          class="change"
          @click="changeFile"
        >
          <div v-show="file.length !== 0 && $route.name == 'edit-surat-keluar'">
            {{ editFile ? 'Akses Dokumen' : 'Ganti Dokumen' }}
          </div>
        </div> -->
        <b-row
          v-if="!editFile"
          class="match-height"
        >
          <b-col
            v-for="item in file"
            v-show="item.file_type === 'Original'"
            :key="item.id"
            md="6 mb-1"
            class="fileSurat"
          >
            <div
              class="open-file"
              @click="openFile(item.access_url)"
            >
              <feather-icon
                icon="FileIcon"
                size="56"
              />
              <h5 class="ml-1">
                Open File
                <span> {{ item.real_filename }}{{ item.ext }} </span>
              </h5>
            </div>
          </b-col>
        </b-row>

        <b-row
          v-else
          class="match-height mt-1"
        >
          <b-col>
            <b-form-group
              label="File Surat"
              label-for="FileSurat"
              class="mt-1"
            >
              <b-form-file
                id="FileSurat"
                ref="file"
                v-model="file"
                type="file"
                placeholder="Input File Surat"
                drop-placeholder="Drop file here..."
                multiple="multiple"
                @change="fileChange"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>
    </b-card-code>

    <b-row class="match-height">
      <b-col md="7">
        <b-card-code title="Validasi Surat Keluar">
          <b-form-group style="position:relative">
            <div v-show="!editValidateParent && $route.name == 'edit-surat-keluar'">
              <div
                class="change"
                @click="changeValidate"
              >
                {{ !editValidate ? 'Ganti Dokumen' : 'Lihat Dokumen' }}
              </div>
            </div>
            <b-row
              v-if="!editValidate"
              class="match-height mt-1 mb-0"
            >
              <b-col
                v-for="item in file"
                v-show="item.file_type === 'Updated'"
                :key="item.id"
                md="12 mb-1"
                class="fileSurat"
              >
                <div
                  class="open-file"
                  @click="openFile(item.access_url)"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="56"
                  />
                  <h5 class="ml-1">
                    Open File
                    <span> {{ item.real_filename }}{{ item.ext }} </span>
                  </h5>
                </div>
              </b-col>
            </b-row>

            <b-row
              v-else
              class="match-height mt-1"
            >
              <b-col>
                <b-form-group
                  label="File Surat"
                  label-for="FileSurat"
                >
                  <b-form-file
                    id="FileSurat"
                    ref="file"
                    v-model="validateFile"
                    type="file"
                    placeholder="Input File Surat"
                    drop-placeholder="Drop file here..."
                    multiple="multiple"
                    @change="validateChange"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group
            label="Status"
            label-for="Status"
            class="mb-2"
          >
            <b-form-select
              v-model="status"
              :options="optionsStatus"
            />
          </b-form-group>

          <b-form-group
            label="Catatan"
            label-for="Catatan"
          >
            <b-form-textarea
              id="textarea-default"
              v-model="note"
              placeholder="Input Catatan"
              rows="3"
            />
          </b-form-group>

          <!--
                <b-button
        v-if="$route.name == 'edit-surat-keluar'"
        variant="outline-primary"
        class="bg-gradient-primary"
        type="submit"
        @click.prevent="validationForm"
      >
        <span class="align-middle">Simpan</span>
      </b-button> -->

          <b-button
            variant="outline-primary"
            class="bg-gradient-primary mt-2"
            style="width: 100px;"
            type="submit"
            @click.prevent="validate"
          >
            <span class="align-middle">Simpan</span>
          </b-button>
        </b-card-code>
      </b-col>

      <b-col md="5">
        <b-card-code
          title="Riwayat Surat Keluar"
          class="activity"
        >
          <app-timeline
            v-for="data in activity"
            :key="data.id"
          >
            <app-timeline-item
              :title="data.user.name"
              :subtitle="data.message"
              :time="dateFormat(data.updated_at)"
              variant="info"
              style="padding-bottom:20px"
            />
          </app-timeline>
        </b-card-code>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BRow,
  BCol,
  BFormFile,
  BFormTags,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import axios from '@axios'
import vSelect from 'vue-select'
import store from '@/store/index'
import dayjs from 'dayjs'
// import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import dayjs from 'dayjs'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    email,
    BFormFile,
    BRow,
    BCol,
    BCardCode,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormTags,
    BFormCheckboxGroup,
    BFormTextarea,
    vSelect,
    AppTimeline,
    AppTimelineItem,
    BFormDatepicker,
  },
  data() {
    return {
      NoSurat: '',
      selectedKategori: [],
      to: '',
      agency: '',
      note: '',
      address: '',
      startDate: null,
      title: '',
      Perihal: '',
      status: '',
      time: '',
      editFile: false,
      editValidate: false,
      editValidateParent: false,
      authorize: false,
      original_letter: '',
      validated_letter: '',
      activity: [],
      file: [],
      validateFile: [],
      temp: [],
      jabatan: '',
      optionsStatus: [
        { value: '', text: 'Pilih  Status' },
        { value: 'Open', text: 'Open' },
        { value: 'Close', text: 'Close' },
      ],
      optionsKategori: [
        { value: 'Undangan', text: 'Undangan' },
        { value: 'Pengumuman', text: 'Pengumuman' },
        { value: 'Pemberitahuan', text: 'Pemberitahuan' },
        { value: 'Tugas', text: 'Tugas' },
        { value: 'Dispensasi', text: 'Dispensasi' },
        { value: 'Pernyataan', text: 'Pernyataan' },
        { value: 'Kuasa', text: 'Kuasa' },
        { value: 'Peringatan', text: 'Peringatan' },
        { value: 'Pemanggilan', text: 'Pemanggilan' },
        { value: 'Tagihan', text: 'Tagihan' },
        { value: 'Pembayaran', text: 'Pembayaran' },
        { value: 'Pengajuan', text: 'Pengajuan' },
        { value: 'Modal', text: 'Modal' },
        { value: 'Mandat', text: 'Mandat' },
        { value: 'Penawaran', text: 'Penawaran' },
        { value: 'Pengantar', text: 'Pengantar' },
      ],
    }
  },
  created() {
    this.authorize = store.state.userData.data.is_admin === 1
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.editSurat()
        }
      })
    },

    async getDetail() {
      const param = this.$route.params.pathMatch
      const { data } = await axios.get(`siap/outbox/detail/${param}`)
      this.NoSurat = data.ref_num
      this.to = data.to
      this.agency = data.from
      this.file = data.file
      if (this.file.length === 0) {
        this.editFile = true
      } else {
        this.editFile = false
      }
      this.address = data.address
      this.Perihal = data.title
      this.selectedKategori = {
        value: data.category.name,
        text: data.category.name,
      }
      this.status = data.status.name
      this.note = data.note
      this.startDate = data.date
      this.validateFile = data.file

      if (this.file === null) {
        this.editValidate = true
      } else {
        const update = this.file.find(d => d.file_type === 'Updated')
        if (update === undefined) {
          this.editValidate = true
          this.editValidateParent = true
        } else {
          this.editValidate = false
        }
      }
      this.getActivity()
    },

    async getActivity() {
      const param = this.$route.params.pathMatch
      const { data } = await axios.get(`siap/outbox/activity/${param}`)
      this.activity = data.data
    },

    openFile(e) {
      window.open(e, '_blank')
    },

    async changeFile() {
      this.editFile = !this.editFile
      const param = this.NoSurat
      const { data } = await axios.get(`siap/outbox/${param}`)
      this.file = data.file
    },

    async changeValidate() {
      this.editValidate = !this.editValidate
      const param = this.NoSurat
      const { data } = await axios.get(`siap/outbox/${param}`)
      this.file = data.file
    },

    goEdit() {
      window.location.href = `/surat-keluar/edit-surat-keluar/${this.NoSurat}`
    },

    async fileChange(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('files', file)
      })
      const { data } = await axios.post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          feature: 'Outbox',
        },
      })
      this.file = data.data.map(v => ({ ...v, file_type: 'Original' }))
    },

    async validateChange(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('files', file)
      })
      const { data } = await axios.post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          feature: 'Outbox',
        },
      })
      this.validateFile = data.data.map(v => ({ ...v, file_type: 'Updated' }))
    },

    async editSurat() {
      const now = dayjs()
      const time = dayjs(now).format('HH:mm:ss')
      const param = this.$route.params.pathMatch
      await axios
        .post(`siap/outbox/update/${param}`, {
          title: this.Perihal,
          cat: this.selectedKategori.value,
          to: this.to,
          from: this.agency,
          address: this.address,
          date: dayjs(this.startDate).format(`YYYY-MM-DDT${time}+07:00`),
          file: this.file.map(e => e.id),
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          this.getDetail()
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error.response.data.error.message,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },

    dateFormat(time) {
      const formatted = dayjs(time).format('DD/MM/YYYY (HH:mm)')
      if (formatted === 'Invalid Date') {
        return '-'
      }
      return formatted
    },

    async validate() {
      const now = dayjs()
      const time = dayjs(now).format('HH:mm:ss')
      const param = this.NoSurat
      await axios
        .post(`siap/outbox/update/${param}`, {
          title: this.Perihal,
          cat: this.selectedKategori.value,
          to: this.to,
          from: this.agency,
          address: this.address,
          date: dayjs(this.startDate).format(`YYYY-MM-DDT${time}+07:00`),
          status: this.status,
          note: this.note,
          file: this.validateFile.concat(
            this.file.filter(v => v.file_type === 'Original'),
          ),
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error.response.data.error.message,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
      this.getDetail()
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
.row {
  margin-bottom: 20px;
}
.form-group {
  margin-bottom: 5px;
}
.nopad {
  padding: 0;
}
.labelfull {
  .custom-checkbox {
    width: 100%;
  }
}
.labelfull .custom-checkbox {
  width: 100%;
}
.tanggapan2 {
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 5px 0;
  .avatar {
    width: 70px;
    margin-right: 20px;
    img {
      width: 100%;
    }
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
    font-weight: 400;
  }
  h4 {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .input {
    margin-left: 25px;
    flex-grow: 1;
  }
  .input-group-append {
    cursor: pointer;
  }
}

.detail-dispo {
  .form-control,
  .custom-select {
    border: unset;
    border-bottom: 1px solid #d8d6de;
    border-radius: 0;
    pointer-events: none;
  }
}

.fileSurat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #a6a6a6;
    display: block;
    margin-top: 5px;
    font-size: 12px;
  }
}

.change {
  position: absolute;
  top: -23px;
  border-bottom: solid 1px;
  cursor: pointer;
  right: 0;
  color: #0059a3;
}
.open-file {
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 14px;
  transition: background-color 0.5s ease;
  &:hover {
    background: #efefef;
  }
}

.activity {
  .card-body {
    max-height: 475px;
    overflow-y: scroll;
  }
}
</style>
